<template>
  <component :is="IMPLEMENTATION[status]" v-bind="props" />
</template>

<script lang="ts" setup>
import {
  LazyMyOrdersScratchcardTicketCardCanceled,
  LazyMyOrdersScratchcardTicketCardPurchased,
  LazyMyOrdersScratchcardTicketCardRevealed,
  LazyMyOrdersScratchcardTicketCardScratched,
} from '#components'

import type { MyOrdersScratchcardTicketCardProps } from '~/features/MyOrders/types'

import { ScratchcardState } from '~/@types/generated/backend/graphql-schema-types'

const IMPLEMENTATION = {
  [ScratchcardState.Cancelled]: LazyMyOrdersScratchcardTicketCardCanceled,
  [ScratchcardState.InProgress]: LazyMyOrdersScratchcardTicketCardPurchased,
  [ScratchcardState.Purchased]: LazyMyOrdersScratchcardTicketCardPurchased,
  [ScratchcardState.Revealed]: LazyMyOrdersScratchcardTicketCardRevealed,
  [ScratchcardState.Scratched]: LazyMyOrdersScratchcardTicketCardScratched,
} as const

const props = defineProps<MyOrdersScratchcardTicketCardProps>()
</script>
