import type { ApolloQueryResult } from '@apollo/client/core'
import type { UseLazyQueryReturn } from '@vue/apollo-composable'

import {
  type Exact,
  type ScratchcardOrderQuery,
  ScratchcardState,
  type WinningsPayload,
} from '~/@types/generated/backend/graphql-schema-types'

export type ScratchcardDetail = ScratchcardOrderDetail['scratchcards'][0]
export type ScratchcardOrderDetail = ScratchcardOrderQuery['scratchcardOrder']

const bySerialNumber =
  (serialNumber: string) => (_scratchcard: ScratchcardDetail) =>
    _scratchcard.serialNumber === serialNumber

const byWinAmount = (
  scratchcardA: ScratchcardDetail,
  scratchcardB: ScratchcardDetail,
) =>
  (scratchcardB.winningsPayload?.amount?.value ?? 0) -
  (scratchcardA.winningsPayload?.amount?.value ?? 0)

const isNotCanceled = (scratchcard: ScratchcardDetail) =>
  scratchcard.status !== ScratchcardState.Cancelled

const isRevealed = (scratchcard: ScratchcardDetail) =>
  scratchcard.status === ScratchcardState.Revealed

const toData = ({ data }: ApolloQueryResult<ScratchcardOrderQuery>) => data

let orderQuery: Maybe<
  UseLazyQueryReturn<ScratchcardOrderQuery, Exact<{ orderId: string }>>
>

let useReveal: Maybe<ReturnType<typeof useRevealScratchcards>>

const order = ref<Maybe<ScratchcardOrderDetail>>(null)

export const useScratchcardOrderSingleton = () => {
  if (!orderQuery) {
    const { params } = useRoute()
    orderQuery = useScratchcardOrderLazyQuery(
      { orderId: params.id },
      { fetchPolicy: 'no-cache' },
    )
  }

  if (!useReveal) {
    useReveal = useRevealScratchcards()
  }

  const { addToastError } = useToaster()

  const orderId = computed(() => order.value?.orderId ?? null)

  const scratchcards = computed(() => order.value?.scratchcards ?? [])

  const imageLinksExpiresInMs = computed(() => {
    const expiresAt = scratchcards.value?.[0]?.images?.linkExpiresAt
    if (!expiresAt) return 0

    return Math.floor(distance(new Date(expiresAt), new Date()))
  })

  const uncanceledScratchcards = computed(
    () => order.value?.scratchcards.filter(isNotCanceled) ?? [],
  )

  const destroy = () => {
    orderQuery = null
    order.value = null
    useReveal = null
  }

  const fetch = async () => {
    if (!orderQuery) return
    const { load, refetch } = orderQuery

    const result = (await load()) || (await refetch()?.then(toData))
    if (!result) return

    order.value = result.scratchcardOrder
  }

  const revealAll = async () => {
    if (useReveal!.isRevealLoading.value || !orderId.value) return

    const response = await useReveal!.revealAll(orderId.value)
    if (!response || !order.value) throw new Error('Response or order missing')

    const currentRevealed = scratchcards.value
      .filter(isRevealed)
      .map(({ serialNumber }) => serialNumber)

    revealScratchcards(response.scratchcards)

    if (!response.winnings) return null

    return (
      response.scratchcards
        .filter(({ serialNumber }) => !currentRevealed.includes(serialNumber))
        .sort(byWinAmount)?.[0]?.winningsPayload ?? null
    )
  }

  const revealOne = async (serialNumber: string) => {
    if (useReveal!.isRevealLoading.value || !orderId.value) return

    const index = scratchcards.value.findIndex(bySerialNumber(serialNumber))
    if (index < 0) throw new Error('Scratchcard not found for given index')

    const winning =
      (await useReveal!.revealOne(orderId.value, serialNumber)) ?? null
    revealScratchcard(index, winning)

    return winning
  }

  const revealScratchcard = (
    index: number,
    winningsPayload: Maybe<WinningsPayload>,
  ) => {
    const scratchcard = order.value?.scratchcards?.[index]
    if (!scratchcard) return

    order.value!.scratchcards.splice(index, 1, {
      ...scratchcard,
      status: ScratchcardState.Revealed,
      winningsPayload,
    })
  }

  const revealScratchcards = (newScratchcards: ScratchcardDetail[]) => {
    if (!order.value) return

    const triggerReveal = ({
      serialNumber,
      winningsPayload,
    }: ScratchcardDetail) => {
      const index = order.value!.scratchcards.findIndex(
        bySerialNumber(serialNumber),
      )
      revealScratchcard(index, winningsPayload || null)
    }

    newScratchcards.filter(isRevealed).forEach(triggerReveal)
  }

  const showErrorToast = (error: unknown) => {
    addToastError({
      error,
      prefix: 'myorders.scratchcard.toast.error',
      tracking: { topicKey: 'scratchcard_order_failed' },
    })
  }

  return {
    destroy,
    fetch,
    imageLinksExpiresInMs,
    isLoading: orderQuery.loading,
    isRevealAllLoading: useReveal.isRevealAllLoading,
    isRevealLoading: useReveal.isRevealLoading,
    onFetchError: orderQuery.onError,
    order: readonly(order),
    revealAll,
    revealOne,
    scratchcards,
    showErrorToast,
    uncanceledScratchcards,
  }
}
