<template>
  <NuxtLayout
    fallback-route="/my-orders/scratchcards"
    name="overlay"
    nav-icon="arrow-left"
    :title="$t('myorders.detail.scratchcard.headline')"
  >
    <MyOrdersCard footer-class="pb-xs" with-wavy-header>
      <template #header>
        <LcSkeleton
          v-if="showSkeletons"
          height="0.875rem"
          radius="small"
          width="50%"
        />

        <ScratchcardTitle v-else v-bind="{ color, title }" size="small" />
      </template>

      <template #header-end>
        <LcSkeleton
          v-if="showSkeletons"
          height="2rem"
          radius="small"
          width="140px"
        />

        <template v-else>
          <MyOrdersScratchcardStatus
            v-if="status && status !== ScratchcardState.Revealed"
            v-bind="{ count, status }"
          />

          <MyOrdersWinningBadge v-else-if="winnings" />
        </template>
      </template>

      <template #list>
        <template v-if="showSkeletons">
          <LcSkeleton v-for="i in 4" :key="i" height="1rem" radius="small" />
        </template>

        <template v-else>
          <IconListItemId v-if="id" v-bind="{ id }" />

          <IconListItemOrderedIn
            v-if="licensedTerritory"
            v-bind="{ licensedTerritory }"
          />

          <IconListItemPrice v-if="price" v-bind="{ price }" />

          <IconListItemScratchcardTicketsAmount
            v-if="quantity"
            v-bind="{ quantity }"
          />
        </template>
      </template>

      <section class="flex flex-col gap-xs mt-md">
        <header>
          {{ $t('myorders.detail.scratchcard.section.tickets.headline') }}
        </header>

        <template v-if="showSkeletons">
          <LcSkeleton v-for="i in 2" :key="i" height="4.5rem" radius="medium" />
        </template>

        <ul v-show="!showSkeletons" class="flex flex-col gap-xs">
          <li
            v-for="scratchcard in scratchcards"
            :key="scratchcard.serialNumber"
          >
            <MyOrdersScratchcardTicketCardControl
              v-bind="scratchcard"
              @click="handleOpenModal(scratchcard.serialNumber)"
            />
          </li>
        </ul>
      </section>

      <MyOrdersWinnings
        v-if="!showSkeletons && winnings"
        :amount="winnings"
        class="mt-md"
        variant="total"
      />

      <footer
        v-if="isLoading || areScratchcardsPresent"
        class="flex flex-col gap-md items-center justify-center mt-lg mb-xs mx-lg"
      >
        <template v-if="showSkeletons">
          <LcSkeleton v-for="i in 2" :key="i" height="3rem" radius="full" />
        </template>

        <template v-else>
          <LcButton
            v-if="areScratchcardsPresent && isUnrevealedScratchcardPresent"
            class="w-full"
            icon="scratch-now"
            size="medium"
            @click="invokeRevealAll"
          >
            {{ $t('myorders.detail.scratchcard.cta.scratchallcards') }}
          </LcButton>

          <LcButton
            v-if="areScratchcardsPresent"
            class="w-full"
            size="medium"
            variant="secondary"
            @click="handleOpenModal()"
          >
            {{ $t('myorders.detail.scratchcard.cta.showall') }}
          </LcButton>
        </template>
      </footer>
    </MyOrdersCard>
  </NuxtLayout>

  <LazyScratchcardScratchingModal
    v-if="isScratchingActive"
    @close="handleCloseModal"
  />

  <ScratchcardScratchingAnimationModal
    v-if="isRevealAllLoading || isRevealLoadingForMinTime"
  />
</template>

<script lang="ts" setup>
import type { LicensedTerritory } from '@lottocom/lottery-mapping'

import { LazyScratchcardScratchingModal } from '#components'

import type { ScratchcardDetail } from '~/composables/scratchcardOrderSingleton'

import { ScratchcardState } from '~/@types/generated/backend/graphql-schema-types'
import { isError } from '~/utils/guards'

const RELOAD_IMAGES_AFTER_MS = 600_000 // 10 minutes
const RELOAD_IMAGES_WITHIN_MODAL_AFTER_MS = 300_000 // 5 minutes

const isScratched = (scratchcard: ScratchcardDetail) =>
  scratchcard.status === ScratchcardState.Scratched

definePageMeta({
  footer: false,
  layout: false,
  middleware: ['auth'],
  tracking: { name: 'MyOrdersScratchcard' },
})

const { t } = useI18n()
const { log } = useDatadog()
const { currentRoute } = useRouter()
const { addToastError } = useToaster()

useHead({ title: t('myorders.detail.pagetitle') })

const {
  destroy,
  fetch,
  imageLinksExpiresInMs,
  isLoading,
  isRevealAllLoading,
  onFetchError,
  order,
  revealAll,
  scratchcards,
  showErrorToast,
  uncanceledScratchcards,
} = useScratchcardOrderSingleton()

const {
  close: closeScratchingModal,
  open: openScratchingModal,
  serialNumber,
} = useScratchcardScratchingModal()

const { pause: stopRefetchInterval, resume: startRefetchInterval } =
  useIntervalFn(
    async () => {
      stopRefetchInterval()
      await fetch()
      startRefetchInterval()
    },
    computed(
      () => imageLinksExpiresInMs.value - RELOAD_IMAGES_WITHIN_MODAL_AFTER_MS,
    ),
    { immediate: false },
  )

const isLoadingForMinTime = refAutoReset(false, 250)
const isRevealLoadingForMinTime = refAutoReset(false, 1_000)

const areScratchcardsPresent = computed(() => {
  const status = [ScratchcardState.Scratched, ScratchcardState.Revealed]
  const orderState = order.value?.state
  return orderState && status.includes(orderState)
})

const color = computed(() => order.value?.visualParameters.titleColor)

const count = computed(() => order.value?.quantityActive)

const fallbackFirstSerialNumber = computed(() => {
  const indexFirstUnrevealed =
    uncanceledScratchcards.value.findIndex(isScratched)

  return uncanceledScratchcards.value[Math.max(0, indexFirstUnrevealed)]
    ?.serialNumber
})

const id = computed(() => order.value?.orderId)

const isScratchingActive = computed(
  () => currentRoute.value.query.scratch === 'true',
)

const isUnrevealedScratchcardPresent = computed(() =>
  order.value?.scratchcards.some(isScratched),
)

const licensedTerritory = computed(
  () => order.value?.licensedTerritory as LicensedTerritory,
)

const price = computed(() => order.value?.price)

const quantity = computed(() => order.value?.quantity)

const showSkeletons = computed(
  () => isLoading.value || isLoadingForMinTime.value,
)

const status = computed(() => order.value?.state)

const title = computed(() => order.value?.gameName || '')

const winnings = computed(() => order.value?.winnings)

const handleCloseModal = async () => {
  stopRefetchInterval()
  await closeScratchingModal()
  await fetch()
}

const handleOpenModal = async (serialNumber?: string) => {
  const ticketSerialNumber = serialNumber || fallbackFirstSerialNumber.value

  if (!ticketSerialNumber || !isUncanceled(ticketSerialNumber)) {
    return closeScratchingModal()
  }

  if (imageLinksExpiresInMs.value <= RELOAD_IMAGES_AFTER_MS) await fetch()

  await openScratchingModal(ticketSerialNumber)
}

const invokeRevealAll = async () => {
  try {
    await revealAll()
    await fetch()
  } catch (error) {
    log('error', {
      component: 'my-orders/scratchcard/[id].vue',
      error,
      message: `Reveal of all scratchcards failed${isError(error) ? ` with ${error.message}` : ''}`,
    })

    showErrorToast(error)
  }
}

const isUncanceled = (serialNumber: string) =>
  uncanceledScratchcards.value.some(
    (scratchcard) => scratchcard.serialNumber === serialNumber,
  )

onFetchError((error) => {
  if (
    isSpecificGraphQLError(error, {
      matchMessage: 'validation.scratchcardorder.notfound',
    })
  ) {
    return show404Error()
  }

  addToastError({
    error,
    prefix: 'myorders.detail.scratchcard.toast.error',
    tracking: { topicKey: 'scratchcard_order_load_failed' },
  })
})

whenever(isRevealAllLoading, () => (isRevealLoadingForMinTime.value = true))
whenever(isLoading, () => (isLoadingForMinTime.value = true))

onMounted(async () => {
  await fetch()
  if (!isScratchingActive.value) return

  await handleOpenModal(serialNumber.value || undefined)
})

onUnmounted(destroy)
</script>
