export const useRevealScratchcards = () => {
  const { log } = useDatadog()

  const { loading: isRevealAllLoading, mutate: revealAll } =
    useRevealScratchcardOrderMutation()

  const { loading: isRevealLoading, mutate: revealOne } =
    useRevealScratchcardMutation()

  const isLoading = computed(
    () => isRevealAllLoading.value || isRevealLoading.value,
  )

  const logError = (error: unknown, fallbackMessage: string) => {
    log('error', {
      component: 'revealScratchcards.ts',
      error,
      message: `Reveal scratchcards failed with ${
        isError(error) ? error.message : fallbackMessage
      }`,
    })
  }

  const revealAllTickets = async (orderId: string) => {
    if (isLoading.value) return null

    try {
      const response = await revealAll({ input: { orderId } })
      return response?.data?.revealOrder || null
    } catch (error) {
      logError(error, 'error occurred at revealAllTickets()')
      throw error
    }
  }

  const revealOneTicket = async (orderId: string, serialNumber: string) => {
    if (isLoading.value) return null

    try {
      const response = await revealOne({ input: { orderId, serialNumber } })
      return response?.data?.revealScratchcard.amount
        ? response?.data?.revealScratchcard
        : null
    } catch (error) {
      logError(error, 'error occurred at revealOneTicket()')
      throw error
    }
  }

  return {
    isLoading,
    isRevealAllLoading,
    isRevealLoading,
    revealAll: revealAllTickets,
    revealOne: revealOneTicket,
  }
}
